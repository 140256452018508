.use-case-container{
  width: 100%;
  height: auto;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.use-case-card{
  height:600px;
  width: 500px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.5);
  background-color: #f5f6f8;
  overflow: hidden;
}
.use-case-arrow>svg{
  fill: #ec008c;
  font-size:65px;
  margin: 0 100px
}
.use-case-card>.use-case-top{
  height:350px;
  width: 100%;
}
.use-case-card>.use-case-top>img{
  height:100%;
  width: 100%;
  object-fit: cover;
}
.use-case-card>.use-case-bottom{
  padding: 2% 8%
}
.use-case-card>.use-case-bottom>h3{
  text-align: left;
}
.use-case-card>.use-case-bottom>p{
  text-align: justify;
  font-size: 14px;
}
.use-cases-landing{
  width: 100%;
  height: auto;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 3% 5%
}
.use-cases-options{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.use-cases-options .use-case-option{
  width: 310px;
  height: 326px;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.5);
  background-color: #ffffff;
  margin: 0 16px 43px 16px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding:20% 8% 8% 8%;
  transition: 0.2s
}
.use-cases-options>a:hover .use-case-option{
  transform: scale(1.03);
}
.use-cases-options .use-case-option>*{
  font-size: 45px
}
.use-cases-options .use-case-option>p{
  font-size: 24px;
  margin-top: 60px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .use-case-card{
    width: 40vw;
    height:52vh
  }
  .use-case-card>.use-case-top{
    height: 20vh
  }
  .use-case-arrow>svg{
    margin:0 10px
  }
}

@media only screen and (max-width: 450px) {
  .use-cases-landing {
    padding-top:10%
  }
  .use-cases-options .use-case-option{
    width: 80vw;
    height: 80vw;
  }
  .use-cases-options .use-case-option>p {
    font-size: 24px;
    margin-top: 40px;
    text-align: center;
  }

  .use-case-container {
    height: auto;
    flex-direction: column;
    padding-top: 10%
  }
  .use-case-card{
    width: 90vw;
    height:auto
  }
  .use-case-card>.use-case-top{
    height: 35vh
  }
  .use-case-arrow{
    transform: rotate(90deg);
    margin: 40px
  }
}

@media only screen
and (min-device-width : 768px)
and (max-width : 1024px)
and (orientation : landscape) {
  .about-one, .about-two {
    height: 80vh
  }
}

@media only screen
and (max-width : 812px) and
(orientation : landscape) {
  .use-case-container {
      height: auto;
      padding: 10% 0;
    }
}
