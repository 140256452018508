.build-main{
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 3rem
}
.start-building{
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.5);
  background-color: #ffffff;
  padding: 2% 5%
}
.step{
  margin-bottom: 2rem
}
.step *:focus{
  outline: none
}
.step-main-title{
  color: var(--main-color);
  font-family: fihogar-bold, sans-serif;
  font-size: 1.5em;
  margin-top: 3em;
}
.step-title{
  font-family: fihogar-bold, sans-serif;
  font-size: 1.5em
}
.step-button{
  font-size: 0.75em;
  color: white;
  background-color: var(--main-color);
  border-radius: 30px;
  padding: 0.4em 2em;
  cursor: pointer;
  border: none
}
.step-link{
  font-size: 1em;
  color: var(--main-color);
  cursor: pointer;
}
@media only screen and (max-width: 450px) {
  .build-main{
    padding: 1rem
  }
  .step-title{
    font-size: 1rem
  }
  .step-text{
    font-size: 0.75rem
  }
}
